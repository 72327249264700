export const environment = {
  production: false,
  useEmulators: false,
  client: 'JUNTAS',
  baseUrl: 'https://us-central1-pq-mibanco-qa.cloudfunctions.net',
  currency: { singular: 'Sol', plural: 'Soles', symbol: 'S/.' },
  firebase: {
    apiKey: 'AIzaSyAacEWhsxwLMLIhVxtid1tPbiEex_ayS8c',
    authDomain: 'pq-mibanco-qa.firebaseapp.com',
    projectId: 'pq-mibanco-qa',
    storageBucket: 'pq-mibanco-qa.appspot.com',
    messagingSenderId: '470769878188',
    appId: '1:470769878188:web:6051d3a5fdab3b4d2ccb09',
    measurementId: 'G-DHGZ9NH8BK'
  }
};