import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Pasanaq-Dashboard';
  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}

  ngOnInit(): void {
    if (environment.client === 'QUANTUM') {
      this.renderer.setAttribute(this.document.body, 'class', 'quantum-theme');
    } else if (environment.client === 'BCP') {
      this.renderer.setAttribute(this.document.body, 'class', 'bcp-theme');
    }else if (environment.client === 'UNACEM') {
      this.renderer.setAttribute(this.document.body, 'class', 'unacem-theme');
    }
    /* ADD THEME FOR JUNTAS
      else if (environment.client === 'JUNTAS') {
        this.renderer.setAttribute(this.document.body, 'class', 'juntas-theme');
      }
    */
  }
}
