import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
  transform(date: any, fixTimezone = false): string {
    if (date === '') return '-';
    if (fixTimezone && environment.client === 'PASANAKUSOL') {
      return moment(date).add(4, 'hours').format('DD/MM/YYYY');
    }
    return moment(date).format('DD/MM/YYYY');
  }
}
