import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() dataSource: any[] = [];
  @Input() columns: any;
  @Input() searchInfo: any;

  displayedColumns: [] = [];

  public data: MatTableDataSource<any> = new MatTableDataSource(this.dataSource);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private liveAnnouncer: LiveAnnouncer) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.data = new MatTableDataSource(changes.dataSource.currentValue);
    this.data.sort = this.sort;
    this.data.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.setDefaultVisibleColumns();
  }

  ngAfterViewInit() {
    this.data.sort = this.sort;
    this.data.paginator = this.paginator;
  }

  setDefaultVisibleColumns(): void {
    this.displayedColumns = this.columns.map((column: any) => column.field);
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }

}
