import { Component, inject } from '@angular/core';
import { UiSharedService } from 'src/app/core/services/ui-shared.service';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss'],
})
export class LoadingPageComponent {
  readonly loading$ = inject(UiSharedService).loading$;
}
