import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { PeriodicityPipe } from './pipes/periodicity.pipe';
import { AlertComponent } from './components/alert/alert.component';
import { AngularMaterialModule } from '../core/angular-material/angular-material.module';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { UrlSafePipe } from './pipes/url-safe.pipe';

@NgModule({
  declarations: [DataGridComponent, CustomTableComponent, PeriodicityPipe, AlertComponent, CustomDatePipe, LoadingPageComponent, UrlSafePipe],
  imports: [CommonModule, AngularMaterialModule],
  exports: [DataGridComponent, PeriodicityPipe, CustomDatePipe, LoadingPageComponent, CustomTableComponent, UrlSafePipe],
})
export class SharedModule {}
