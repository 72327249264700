import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/features/auth/services/auth.service';
import { DashboardConfigService } from '../../services/dashboard-config.service';
import { Roles } from '../../enums/roles.enum';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  readonly isAdmin$ = this.authService.isAdmin();
  readonly user$ = this.authService.currentUser();
  readonly #role$ = this.authService.getUserRole();
  readonly #isAdminOrSupervisor = this.#role$.pipe(
    map((role) => [Roles.ADMIN, Roles.SUPERVISOR].some((r) => r === role))
  );
  readonly #isValidator$ = this.#role$.pipe(map((role) => role === Roles.VALIDATOR));

  readonly canSeeSavingPlans$ = combineLatest([
    this.#isAdminOrSupervisor,
    this.dashboardConfigService.dashboardConfig$,
  ]).pipe(map(([role, config]) => role && config?.savingPlans));

  readonly canSeeEndUsers$ = combineLatest([
    this.#isAdminOrSupervisor,
    this.dashboardConfigService.dashboardConfig$,
  ]).pipe(map(([role, config]) => role && config?.showEndUsers));

  readonly canSeePayments$ = combineLatest([
    this.#isValidator$,
    this.dashboardConfigService.dashboardConfig$,
  ]).pipe(map(([role, config]) => role && config?.paymentList));

  constructor(
    private router: Router,
    private authService: AuthService,
    public dashboardConfigService: DashboardConfigService
  ) {}

  async logOut() {
    await this.authService.logout();
    this.router.navigate(['']);
  }
}
