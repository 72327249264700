<div class="table-container">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="announceSortChange($event)"
    matSortDisableClear
    class="metric-table"
  >
    <ng-container *ngFor="let col; let i = index; of: columns">
      <ng-container [matColumnDef]="col">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ labels[i] }}</th>

        <ng-container [ngSwitch]="types[i]">

          <ng-container *ngSwitchCase="'date'">
            <td mat-cell *matCellDef="let element" >
              {{ (element[col] | customDate) }}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'numeric'">
            <td mat-cell *matCellDef="let element" >
              {{ (element[col] | number) }}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'percentage'">
            <td mat-cell *matCellDef="let element" >
              {{ (element[col] | percent) }}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'decimal'">
            <td mat-cell *matCellDef="let element" >
              {{ (element[col] | number: '0-2') }}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'name'">
            <td mat-cell *matCellDef="let element" >
              {{ (element[col] | titlecase) }}
            </td>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <td mat-cell *matCellDef="let element" >
              {{ (element[col]) }}
            </td>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons> </mat-paginator>
