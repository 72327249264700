import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiSharedService {
  #loading: { state: boolean; message?: string } = { state: false };
  #loading$ = new BehaviorSubject(this.#loading);
  readonly loading$ = this.#loading$.asObservable();

  public startLoading(message?: string) {
    this.#loading$.next({ state: true, message: message ?? 'Por favor, espera un momento' });
  }

  public stopLoading() {
    this.#loading$.next({ state: false });
  }
}
