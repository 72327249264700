import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/features/auth/services/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(): Observable<boolean | UrlTree> {
    return this.isAdmin();
  }
  canLoad(): Observable<boolean | UrlTree> {
    return this.isAdmin();
  }

  private isAdmin() {
    return this.authService
      .isAdmin()
      .pipe(
        map((isAdmin) =>
          isAdmin ? true : this.router.parseUrl('/dashboard/analytics')
        )
      );
  }
}
