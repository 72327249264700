import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DashboardConfigService } from '../services/dashboard-config.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigGuard {
  constructor(private dashboardConfigService: DashboardConfigService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const field = route.data.field ?? 'not-exist';
    return this.dashboardConfigService.dashboardConfig$.pipe(
      map((config: any) => (config[field] ? true : this.router.parseUrl('dashboard/analytics')))
    );
  }
}
