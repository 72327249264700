import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'periodicity'
})
export class PeriodicityPipe implements PipeTransform {

  transform(value: unknown): unknown {
    let label = '';

    switch (value) {
    case 0:
      label = 'Bi-semanal';
      break;
    case 1:
      label = 'Mensual';
      break;
    case 2:
      label = 'Bi-mensual';
      break;
    case 3:
      label = 'Semanal';
      break;

    default:
      break;
    }

    return label;
  }

}
