import { Component } from '@angular/core';
import pkg from 'package.json';
@Component({
  selector: 'pq-signature',
  templateUrl: './pq-signature.component.html',
  styleUrls: ['./pq-signature.component.scss'],
  standalone: true
})
export class PqSignatureComponent {
  public appVersion = pkg.version;
  constructor() { }

}
