import { Injectable } from '@angular/core';
import { LoginResult } from 'src/app/core/models/login-result';
import { Roles } from 'src/app/core/enums/roles.enum';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DashboardConfigService } from '../../../core/services/dashboard-config.service';
import { Auth, GoogleAuthProvider, authState, createUserWithEmailAndPassword, getAuth, getIdTokenResult, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signOut } from '@angular/fire/auth';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private roles = Object.values(Roles);
  constructor(
    private auth: Auth,
    private dashboardConfigService: DashboardConfigService,
  ) {
    this.auth.languageCode = 'es';
  }

  async loginWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<LoginResult> {
    try {
      const userCredentials =
        await signInWithEmailAndPassword(this.auth, email, password);
      const user = userCredentials.user;
      const tokenResult = await getIdTokenResult(user);
      const role = tokenResult?.claims.role;
      if (!role && !this.roles.some((d) => d === role)) {
        await signOut(this.auth);
        return new LoginResult(false, 'Acceso no autorizado');
      }

      return new LoginResult(true, '¡Bienvenido!');
    } catch (error: any) {
      if (error.code === 'auth/invalid-email') {
        return new LoginResult(false, 'Correo electronico invalido');
      } else if (error.code === 'auth/user-not-found') {
        return new LoginResult(false, 'Usuario no registrado');
      } else if (error.code === 'auth/wrong-password') {
        return new LoginResult(false, 'Contraseña inválida');
      } else if (error.code === 'permission-denied') {
        return new LoginResult(false, 'Acceso no autorizado');
      } else {
        return new LoginResult(false, 'Lo sentimos ocurrió un error inesperado');
      }
    }
  }

  async createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<boolean> {
    try {
      await createUserWithEmailAndPassword(this.auth, email, password);
      return true;
    } catch (e) {
      console.warn(e);
      return false;
    }
  }

  async sendPasswordResetEmail(email: string): Promise<boolean> {
    try {
      await sendPasswordResetEmail(this.auth, email);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async logout() {
    await signOut(this.auth);
  }

  public firebaseToken(): Observable<string | undefined> {
    return authState(this.auth).pipe(switchMap(async (user) => user?.getIdToken())
    );
  }

  public isAdmin(): Observable<boolean> {
    return authState(this.auth).pipe(
      switchMap(async (user) => user?.getIdTokenResult()),
      map((res) => res?.claims.role === Roles.ADMIN)
    );
  }

  public getUserRole(): Observable<string> {
    return authState(this.auth).pipe(
      switchMap(async (user) => user?.getIdTokenResult()),
      map((res) => res?.claims.role ?? 'NONE')
    );
  }

  public isLogged(): Observable<boolean> {
    return authState(this.auth).pipe(map((user) => !!user));
  }

  public currentUser() {
    return authState(this.auth);
  }

  public async signInByGoogle(){
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      const isValidUser = await this.isValidUser();
      if(!isValidUser) {
        await signOut(auth);
        return new LoginResult(false, 'Acceso no autorizado, solicite permisos a un Administrador.');
      }
      return new LoginResult(true, '¡Bienvenido!');
    }catch (error: any) {
      return new LoginResult(false, 'Accesso no autorizado');
    }
  }

  private async isValidUser(){
    const tokenResult = await this.auth.currentUser?.getIdTokenResult();
    const role = tokenResult?.claims.role;
    if (!role && !this.roles.some((d) => d === role)) {
      return false;
    }
    return true;
  }

}
