import { DashboardConfig } from '../models/dashboard-config';
import { map, filter, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  CollectionReference,
  DocumentData,
  doc,
  onSnapshot,
  Firestore,
  collection,
} from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardConfigService {
  private dashboardConfigCollection: CollectionReference<DocumentData>;
  private dashboardConfig: DashboardConfig = new DashboardConfig();
  private _dasboardConfig$ = new BehaviorSubject<DashboardConfig>(this.dashboardConfig);

  readonly clientName = environment.client;

  get dashboardConfig$() {
    return this._dasboardConfig$.asObservable();
  }

  constructor(public db: Firestore) {
    this.dashboardConfigCollection = collection(this.db, 'Admin');
    this.getConfig();
  }

  private async getConfig() {
    onSnapshot(doc(this.db, 'Admin', 'dashboardConfig'), (doc) => {
      this.dashboardConfig = new DashboardConfig().from(doc.data());
      this._dasboardConfig$.next(this.dashboardConfig);
    });
  }

  public async getTitle() {
    return await this.dashboardConfig$
      .pipe(
        map((res) => res.title),
        filter((res) => res !== undefined),
        take(1)
      )
      .toPromise();
  }
}
