import { AuthService } from 'src/app/features/auth/services/auth.service';
import { Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CheckLoginGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService
      .isLogged()
      .pipe(
        map((isLogged) => (isLogged ? true : this.router.parseUrl('/auth')))
      );
  }
}
