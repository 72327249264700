<table
  mat-table
  [dataSource]="data"
  matSort
  (matSortChange)="announceSortChange($event)"
  class="mat-elevation-z1 groups-table"
  *ngIf="dataSource.length !== 0"
>
  <ng-container *ngFor="let column of columns" [matColumnDef]="column.field">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.title }}</th>

    <ng-container [ngSwitch]="column.field">
      <ng-container *ngSwitchCase="'date'">
        <td mat-cell *matCellDef="let element">
          {{ element[column.field] | date : 'dd/MM/yyyy' }}
        </td>
      </ng-container>
      <ng-container *ngSwitchCase="'periodicity'">
        <td mat-cell *matCellDef="let element">{{ element[column.field] | periodicity }}</td>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <td mat-cell *matCellDef="let element">{{ element[column.field] }}</td>
      </ng-container>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator
  class="mat-elevation-z1 paginator"
  [pageSizeOptions]="[5, 10, 20]"
  showFirstLastButtons
  *ngIf="dataSource.length !== 0"
>
</mat-paginator>

<div class="nodata-container" *ngIf="dataSource.length === 0">
  <div class="nodata-message">{{ searchInfo.message }}</div>
</div>