import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/features/auth/services/auth.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.authService.firebaseToken().pipe(
      switchMap((res) => {
        if (res) {
          const requestClone = request.clone({
            setHeaders: { Authorization: res },
          });
          return next.handle(requestClone);
        } else {
          return next.handle(request);
        }
      })
    );
  }
}
