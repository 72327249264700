<mat-sidenav-container class="sidenav-container">
    <mat-sidenav mode="side" opened class="sidenav-bar">
        <app-sidebar></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
        <router-outlet></router-outlet>
        <div class="signature">
            <pq-signature></pq-signature>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
