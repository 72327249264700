import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DisplayColumn, FormatColumns } from 'src/app/features/users/domain/user.type';


@Component({
  selector: 'pq-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public dataSource!: MatTableDataSource<any>;
  @Input({ required: true })
  set data(value: any[]) {
    this.dataSource = new MatTableDataSource(value);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public columns: string[] = [];
  public labels: string[] = [];
  public types: FormatColumns[] = [];
  @Input({ required: true })
  set displayedColumns(value: DisplayColumn[]) {
    this.columns = value.map((d) => d.column);
    this.labels = value.map((d) => d.label);
    this.types = value.map((d) => d.type);
  }

  constructor(private liveAnnouncer: LiveAnnouncer) {}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }
}
